<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :fields="fields"
                    head-row-variant="secondary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                      >
                        </b-form-checkbox>
                    </template>
                </b-table>
            </b-card>
            <vue-context ref="menu">
                <li>
                    <b-link
                    class="d-flex align-items-center"
                    @click="addTariff"
                    >
                    <feather-icon
                    icon="PlusIcon"
                    size="16"
                    />
                    <span class="ml-75">Добавить</span>
                    </b-link>

                    <b-link
                    class="d-flex align-items-center"
                    @click="editTariff"
                    >
                    <feather-icon
                    icon="Edit3Icon"
                    size="16"
                    />
                    <span class="ml-75">Изменить</span>
                    </b-link>

                    <b-link
                    class="d-flex align-items-center"
                    @click="deleteTariff"
                    >
                    <feather-icon
                    icon="CloseIcon"
                    size="16"
                    />
                        <span class="ml-75">Удалить</span>
                    </b-link>
                </li>
            </vue-context>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    components:{
        VueContext
    },
    data(){
        return{
            fields: [
            {key: 'checkbox', label: '', thStyle: {width: '30px'}},
            {key: 'id', label: 'Id', sortable: true, thStyle: {width: '150px'}},
            {key: 'start', label: 'Начало', sortable: true, thStyle: {width: '150px'} },
            {key: 'end', label: 'Конец', sortable: true, thStyle: {width: '150px'} },
            ],  
        }
    },
    methods:{
        
    },
    mounted(){
        resizeable()
    }
}
</script> 